/*
 * @Description:
 * @Version: 1.0
 * @Autor: silencc
 * @Date: 2020-11-13 16:45:33
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-12-13 18:19:59
 */

/**
     * ruleTypes: 规则类型
     * genRules： rule 生成函数
     * ctx： Vue实例
     */
import { genModels } from '@/plugins/widget/c-form/rules';
import {
orderStatus, deliveryType } from
'@/utils/options';
import { getLabel } from '@/utils/options';
import { orderExamineStatus } from "../../../utils/options";

export var models = genModels(function (genRules, ctx) {return [
  {
    span: 8,
    kname: 'contrNum',
    itemProps: {
      label: '合同编号：' },

    component: {
      name: 'text' } },


  {
    span: 8,
    kname: 'contrName',
    itemProps: {
      label: '合同名称：' },

    component: {
      name: 'text' } },


  {
    span: 8,
    kname: 'contrCo',
    itemProps: {
      label: '合作单位：' },

    component: {
      name: 'text' } },


  {
    span: 8,
    kname: 'saleName',
    itemProps: {
      label: '销售名称：' },

    component: {
      name: 'text' } },


  {
    span: 8,
    kname: 'saleMobile',
    itemProps: {
      label: '销售电话：' },

    component: {
      name: 'text' } },


  {
    span: 8,
    kname: 'createAt',
    itemProps: {
      label: '创建时间：' },

    component: {
      name: 'text' } },


  {
    span: 8,
    kname: 'custName',
    itemProps: {
      label: '送检用户名称：' },

    component: {
      name: 'text' } },


  {
    span: 8,
    kname: 'custMobile',
    itemProps: {
      label: '用户电话：' },

    component: {
      name: 'text' } },


  {
    span: 8,
    kname: 'custEmail',
    itemProps: {
      label: '用户邮箱：' },

    component: {
      name: 'text' } },


  {
    span: 8,
    kname: 'orderId',
    itemProps: {
      label: '送样批次：' },

    component: {
      name: 'text' } },


  {
    span: 8,
    kname: 'deliveryType',
    itemProps: {
      label: '送样类型：' },

    component: {
      name: 'text' },

    valueFilter: function valueFilter(v) {
      return getLabel(deliveryType, v);
    } },

  {
    span: 8,
    kname: 'samples',
    itemProps: {
      label: '样本数：' },

    component: {
      name: 'text' } },


  {
    span: 24,
    kname: 'comment',
    itemProps: {
      label: '订单备注：' },

    component: {
      name: 'text' } }];});



export var models2 = genModels(function (genRules, ctx) {return [
  {
    span: 8,
    kname: 'pkgCom',
    itemProps: {
      label: '快递名称：' },

    component: {
      name: 'text' } },


  {
    span: 8,
    kname: 'pkgNum',
    itemProps: {
      label: '快递单号：' },

    component: {
      name: 'text' } },


  {
    //到样时间
    span: 8,
    kname: 'pkgTime',
    component: {
      name: 'text' } }];});



export var models3 = genModels(function (genRules, ctx) {return [
  {
    span: 8,
    kname: 'status',
    itemProps: {
      label: '状态：' },

    component: {
      name: 'text',
      textStyle: {
        fontWeight: 'bold',
        color: '#618CFF' } },


    valueFilter: function valueFilter(v) {
      return getLabel(orderExamineStatus, v);
    } },

  {
    span: 8,
    kname: 'opTime',
    component: {
      name: 'text' } },


  {
    span: 24,
    kname: 'comment',
    itemProps: {
      label: '原因：' },

    component: {
      name: 'text' },

    valueFilter: function valueFilter(v) {
      return v === undefined ? "无" : v;
    } }];});


export var columns = function columns(ctx) {return [
  {
    label: '序号',
    kname: 'index',
    width: 100 },

  {
    label: '样本名称',
    kname: 'name' },

  {
    label: '样本类型',
    kname: 'deliveryType' },

  {
    label: '物种',
    kname: 'species' },

  {
    label: '送样量',
    kname: 'quantity' },

  /*{
                           label: '患者查看权限',
                           kname: 'patientVisible',
                           filterFn: (v)=>{
                             return getLabel(visible,v)
                           }
                         },*/
  {
    label: '运输方式',
    kname: 'transport' },

  {
    label: '备注',
    kname: 'comment' }

  /*{
                         label: '操作',
                         kname: 'btns',
                         width: 260
                       }*/];};



export var formData = function formData() {return {
    list: [{}, {}] };};