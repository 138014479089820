var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticStyle: { padding: "20px", "box-sizing": "border-box" }
    },
    [
      _c(
        "c-box",
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.close }
            },
            [_vm._v(" 关闭 ")]
          ),
          _vm.form.status == 1
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.cancelOrder }
                },
                [_vm._v(" 撤销 ")]
              )
            : _vm._e(),
          _vm.form.status == 0 || _vm.form.status == 2
            ? _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.againCreate }
                },
                [_vm._v(" 编辑提交 ")]
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "c-box",
        {
          staticStyle: { overflow: "auto" },
          attrs: { height: "calc(100vh - 60px)" }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                size: _vm.size,
                "label-width": _vm.labelWidth
              },
              nativeOn: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.validateSubmit($event)
                }
              }
            },
            [
              _vm.form.status !== 0
                ? _c(
                    "c-box",
                    { staticClass: "shadow" },
                    [
                      _c(
                        "c-box",
                        {
                          attrs: {
                            lineheight: "40px",
                            height: "40px",
                            border: { bottom: "1px solid #E8E8E8" }
                          }
                        },
                        [_vm._v("订单状态 ")]
                      ),
                      _c("c-form-row", {
                        attrs: { list: _vm.models3, readOnly: _vm.readOnly },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "comment",
                              fn: function(scope) {
                                return [
                                  _c(
                                    "el-form-item",
                                    _vm._b(
                                      {},
                                      "el-form-item",
                                      scope.data.itemProps,
                                      false
                                    ),
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.form.audit.comment) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            },
                            {
                              key: "opTime",
                              fn: function() {
                                return [
                                  _vm.form.status == 2
                                    ? _c(
                                        "el-form-item",
                                        { attrs: { label: "驳回时间：" } },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.form.audit.opTime) +
                                              " "
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          3942125341
                        ),
                        model: {
                          value: _vm.form,
                          callback: function($$v) {
                            _vm.form = $$v
                          },
                          expression: "form"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "c-box",
                { staticClass: "shadow" },
                [
                  _c(
                    "c-box",
                    {
                      attrs: {
                        lineheight: "40px",
                        height: "40px",
                        border: { bottom: "1px solid #E8E8E8" }
                      }
                    },
                    [_vm._v("基本信息")]
                  ),
                  _c("c-form-row", {
                    attrs: { list: _vm.models, readOnly: _vm.readOnly },
                    scopedSlots: _vm._u([
                      {
                        key: "auditComment",
                        fn: function(scope) {
                          return undefined
                        }
                      },
                      {
                        key: "orderId",
                        fn: function(scope) {
                          return [
                            _c(
                              "el-form-item",
                              _vm._b(
                                {},
                                "el-form-item",
                                scope.data.itemProps,
                                false
                              ),
                              [
                                _vm._v(
                                  " 批次" + _vm._s(_vm.form.contrRev) + " "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.form,
                      callback: function($$v) {
                        _vm.form = $$v
                      },
                      expression: "form"
                    }
                  })
                ],
                1
              ),
              _c(
                "c-box",
                { staticClass: "shadow" },
                [
                  _c(
                    "c-box",
                    {
                      attrs: {
                        lineheight: "40px",
                        height: "40px",
                        border: { bottom: "1px solid #E8E8E8" }
                      }
                    },
                    [_vm._v("物流信息 ")]
                  ),
                  _c("c-form-row", {
                    attrs: { list: _vm.models2, readOnly: _vm.readOnly },
                    scopedSlots: _vm._u([
                      {
                        key: "pkgTime",
                        fn: function() {
                          return [
                            _vm.form.status == 3
                              ? _c(
                                  "el-form-item",
                                  { attrs: { label: "到样时间：" } },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.form.audit.opTime) + " "
                                    )
                                  ]
                                )
                              : _vm._e()
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.form,
                      callback: function($$v) {
                        _vm.form = $$v
                      },
                      expression: "form"
                    }
                  })
                ],
                1
              ),
              _c(
                "c-box",
                { staticClass: "shadow" },
                [
                  _c(
                    "c-box",
                    {
                      attrs: {
                        lineheight: "40px",
                        height: "40px",
                        border: { bottom: "1px solid #E8E8E8" }
                      }
                    },
                    [_vm._v("样本信息")]
                  ),
                  _vm.form.deliveryType === 0
                    ? _c(
                        "div",
                        [
                          _c("Tissue", {
                            attrs: { id: _vm.id, showBtn: false }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.deliveryType === 1
                    ? _c(
                        "div",
                        [
                          _c("NucleicAcid", {
                            attrs: { id: _vm.id, showBtn: false }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.form.deliveryType === 2
                    ? _c(
                        "div",
                        [
                          _c("Library", {
                            attrs: { id: _vm.id, showBtn: false }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("FormDialog", { ref: "FormDialog", on: { refresh: _vm.getData } }),
      _c("ImageFormDialog", {
        ref: "ImageFormDialog",
        on: { refresh: _vm.getData }
      }),
      _c("FormDialogPass", {
        ref: "FormDialogPass",
        on: { refresh: _vm.getData }
      }),
      _c("FormDialogReject", {
        ref: "FormDialogReject",
        on: { refresh: _vm.getData }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }